export default {
  headers: [
    {
      id: 'main-header',
      logo: {
        alt: 'logo',
        src: 'https://si-homelight.s3.amazonaws.com/sites/vrgada/logo.png',
        url: 'https://www.momentumluxhomes.com/',
      },
      breakpoint: 1220,
      desktopMenu: {
        type: 'default', // default, split, center, single, one-offs
        disableOnHoverMenuExpand: false,
        logo: {
          show: true,
          reverseOrder: false,
          containerClassName: '',
          imageClassName: 'on-vrgada-header-logo',
        },
        headerClassName: 'on-vrgada-header',
        headerSectionsClassName: 'on-vrgada-header-section',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        itemClassName: 'on-vrgada-header-item',
        menuClassName: 'on-vrgada-header-folder',
        menuLabelActiveClassName: '',
        menuItemClassName: 'on-vrgada-header-folder-item',
        subMenuClassName: '',
        subMenuLabelActiveClassName: '',
        subMenuItemClassName: '',
        items: [
          {
            id: 'header-buy-item',
            content: 'BUY',
            menuItems: [
              {
                id: 'header-buy-item-buy',
                content: 'BUY',
                url: 'https://search.momentumluxhomes.com/',
              },
              {
                id: 'header-buy-item-cash-offer',
                content: 'CASH OFFER',
                url: 'https://cash-offer-vrgada.netlify.app/',
              },
            ],
          },
          {
            id: 'sell-item',
            content: 'SELL',
            menuItems: [
              {
                id: 'sell-item-home-val',
                url: 'https://www.momentumluxhomes.com/home-valuation',
                content: 'HOME VALUATION',
              },
              {
                id: 'sell-item-why-with-us',
                url: 'https://www.momentumluxhomes.com/why-list-with-us',
                content: 'WHY LIST WITH US',
              },
              {
                id: 'sell-item-trade-in',
                url: 'https://trade-in-vrgada.netlify.app/',
                content: 'TRADE IN',
              },
            ],
          },
          {
            id: 'header-communities-item',
            content: 'COMMUNITIES',
            menuItems: [
              {
                id: 'header-communities-item-miami',
                content: 'MIAMI',
                url: 'https://www.momentumluxhomes.com/communities/miami-real-estate',
              },
              {
                id: 'header-communities-item-coconut-grove',
                content: 'COCONUT GROVE',
                url: 'https://www.momentumluxhomes.com/communities/coconut-grove-real-estate',
              },
              {
                id: 'header-communities-item-downtown',
                content: 'DOWNTOWN MIAMI',
                url: 'https://www.momentumluxhomes.com/communities/downtown-miami-real-estate',
              },
              {
                id: 'header-communities-item-brickell-miami',
                content: 'BRICKELL MIAMI',
                url: 'https://www.momentumluxhomes.com/communities/brickell-miami-real-estate',
              },
              {
                id: 'header-communities-item-bal-harbour',
                content: 'BAL HARBOUR',
                url: 'https://www.momentumluxhomes.com/communities/bal-harbour-real-estate',
              },
              {
                id: 'header-communities-item-bay-harbor',
                content: 'BAY HARBOR ISLANDS',
                url: 'https://www.momentumluxhomes.com/communities/bay-harbor-islands-real-estate',
              },
              {
                id: 'header-communities-item-coral-gables',
                content: 'CORAL GABLES',
                url: 'https://www.momentumluxhomes.com/communities/coral-gables-real-estate',
              },
              {
                id: 'header-communities-item-key-biscayne',
                content: 'KEY BISCAYNE',
                url: 'https://www.momentumluxhomes.com/communities/key-biscayne-real-estate',
              },
              {
                id: 'header-communities-item-miami-beach',
                content: 'MIAMI BEACH',
                url: 'https://www.momentumluxhomes.com/communities/miami-beach-real-estate',
              },
              {
                id: 'header-communities-item-miami-gardens',
                content: 'MIAMI GARDENS',
                url: 'https://www.momentumluxhomes.com/communities/miami-gardens-real-estate',
              },
              {
                id: 'header-communities-item-more-communities',
                content: 'MORE COMMUNITIES',
                url: 'https://www.momentumluxhomes.com/communities',
              },
            ],
          },
          {
            id: 'header-new-developments-item',
            content: 'NEW DEVELOPMENTS',
            menuItems: [
              {
                id: 'header-developments-item-condos',
                content: 'CONDOS',
                url: 'https://www.momentumluxhomes.com/condos',
              },
              {
                id: 'header-developments-item-ocean',
                content: '2000 OCEAN',
                url: 'https://www.momentumluxhomes.com/2000-ocean',
              },
              {
                id: 'header-developments-item-baccarat',
                content: 'BACCARAT RESIDENCES MIAMI',
                url: 'https://www.momentumluxhomes.com/baccarat',
              },
              {
                id: 'header-developments-item-casa-bella',
                content: 'CASA BELLA RESIDENCES',
                url: 'https://www.momentumluxhomes.com/casabella',
              },
              {
                id: 'header-developments-item-diesel',
                content: 'DIESEL WYNWOOD',
                url: 'https://www.momentumluxhomes.com/diesel-wynwood',
              },
              {
                id: 'header-developments-item-lofty',
                content: 'LOFTY - MIAMI | WATERFRONT',
                url: 'https://www.momentumluxhomes.com/lofty',
              },
              {
                id: 'header-developments-item-smart-brickell',
                content: 'SMART BRICKELL TOWER 3',
                url: 'https://www.momentumluxhomes.com/smart-brickell-t3',
              },
              {
                id: 'header-developments-item-the-crosby',
                content: 'THE CROSBY MIAMI WORLDCENTER',
                url: 'https://www.momentumluxhomes.com/the-crosby-miami',
              },
              {
                id: 'header-developments-item-waldorf',
                content: 'WALDORF MIAMI',
                url: 'https://www.momentumluxhomes.com/waldorf-miami',
              },
            ],
          },
          {
            id: 'header-financing-item',
            content: 'FINANCING',
            url: 'https://www.momentumluxhomes.com/financing',
          },
          {
            id: 'header-team-item',
            content: 'TEAM',
            url: 'https://www.momentumluxhomes.com/team-page',
          },
          {
            id: 'header-contact-item',
            content: 'CONTACT US',
            url: 'https://www.momentumluxhomes.com/new-contact-us',
          },
          {
            id: 'header-blog-item',
            content: 'BLOG',
            url: 'https://www.momentumluxhomes.com/blog',
          },
          {
            id: 'header-home-item',
            content: 'HOME',
            url: 'https://www.momentumluxhomes.com/',
          },
        ],
      },
      mobileMenu: {
        logo: {
          show: true,
          containerClassName: '',
          imageClassName: 'on-vrgada-mheader-logo',
        },
        type: 'mobile-default', // mobile-default, mobile-reversed, mobile-center
        menuOpener: {
          type: 'hamburger',
          className: '',
          hide: false,
          reverse: false,
        },
        headerClassName: 'on-vrgada-mheader',
        headerSectionsClassName: '',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        mobileMenuClassName: 'on-vrgada-mheader-menu',
        itemClassName: 'on-vrgada-mheader-item',
        menuClassName: 'on-vrgada-mheader-folder',
        menuLabelActiveClassName: 'on-vrgada-mheader-active',
        menuItemClassName: 'on-vrgada-mheader-folder-item',
        subMenuClassName: '',
        subMenuItemClassName: '',
        headerItems: [],
        items: [
          {
            id: 'header-mobile-buy-item',
            content: 'BUY',
            menuItems: [
              {
                id: 'header-mobile-buy-item-buy',
                content: 'BUY',
                url: 'https://search.momentumluxhomes.com/',
              },
              {
                id: 'header-mobile-buy-item-cash-offer',
                content: 'CASH OFFER',
                url: 'https://cash-offer-vrgada.netlify.app/',
              },
            ],
          },
          {
            id: 'sell-item',
            content: 'SELL',
            menuItems: [
              {
                id: 'sell-item-home-val',
                url: 'https://www.momentumluxhomes.com/home-valuation',
                content: 'HOME VALUATION',
              },
              {
                id: 'sell-item-why-with-us',
                url: 'https://www.momentumluxhomes.com/why-list-with-us',
                content: 'WHY LIST WITH US',
              },
              {
                id: 'sell-item-trade-in',
                url: 'https://trade-in-vrgada.netlify.app/',
                content: 'TRADE IN',
              },
            ],
          },
          {
            id: 'header-mobile-communities-item',
            content: 'COMMUNITIES',
            menuItems: [
              {
                id: 'header-mobile-communities-item-miami',
                content: 'MIAMI',
                url: 'https://www.momentumluxhomes.com/communities/miami-real-estate',
              },
              {
                id: 'header-mobile-communities-item-coconut-grove',
                content: 'COCONUT GROVE',
                url: 'https://www.momentumluxhomes.com/communities/coconut-grove-real-estate',
              },
              {
                id: 'header-mobile-communities-item-downtown',
                content: 'DOWNTOWN MIAMI',
                url: 'https://www.momentumluxhomes.com/communities/downtown-miami-real-estate',
              },
              {
                id: 'header-mobile-communities-item-brickell-miami',
                content: 'BRICKELL MIAMI',
                url: 'https://www.momentumluxhomes.com/communities/brickell-miami-real-estate',
              },
              {
                id: 'header-mobile-communities-item-bal-harbour',
                content: 'BAL HARBOUR',
                url: 'https://www.momentumluxhomes.com/communities/bal-harbour-real-estate',
              },
              {
                id: 'header-mobile-communities-item-bay-harbor',
                content: 'BAY HARBOR ISLANDS',
                url: 'https://www.momentumluxhomes.com/communities/bay-harbor-islands-real-estate',
              },
              {
                id: 'header-mobile-communities-item-coral-gables',
                content: 'CORAL GABLES',
                url: 'https://www.momentumluxhomes.com/communities/coral-gables-real-estate',
              },
              {
                id: 'header-mobile-communities-item-key-biscayne',
                content: 'KEY BISCAYNE',
                url: 'https://www.momentumluxhomes.com/communities/key-biscayne-real-estate',
              },
              {
                id: 'header-mobile-communities-item-miami-beach',
                content: 'MIAMI BEACH',
                url: 'https://www.momentumluxhomes.com/communities/miami-beach-real-estate',
              },
              {
                id: 'header-mobile-communities-item-miami-gardens',
                content: 'MIAMI GARDENS',
                url: 'https://www.momentumluxhomes.com/communities/miami-gardens-real-estate',
              },
              {
                id: 'header-mobile-communities-item-more-communities',
                content: 'MORE COMMUNITIES',
                url: 'https://www.momentumluxhomes.com/communities',
              },
            ],
          },
          {
            id: 'header-mobile-new-developments-item',
            content: 'NEW DEVELOPMENTS',
            menuItems: [
              {
                id: 'header-mobile-developments-item-condos',
                content: 'CONDOS',
                url: 'https://www.momentumluxhomes.com/condos',
              },
              {
                id: 'header-mobile-developments-item-ocean',
                content: '2000 OCEAN',
                url: 'https://www.momentumluxhomes.com/2000-ocean',
              },
              {
                id: 'header-mobile-developments-item-baccarat',
                content: 'BACCARAT RESIDENCES MIAMI',
                url: 'https://www.momentumluxhomes.com/baccarat',
              },
              {
                id: 'header-mobile-developments-item-casa-bella',
                content: 'CASA BELLA RESIDENCES',
                url: 'https://www.momentumluxhomes.com/casabella',
              },
              {
                id: 'header-mobile-developments-item-diesel',
                content: 'DIESEL WYNWOOD',
                url: 'https://www.momentumluxhomes.com/diesel-wynwood',
              },
              {
                id: 'header-mobile-developments-item-lofty',
                content: 'LOFTY - MIAMI | WATERFRONT',
                url: 'https://www.momentumluxhomes.com/lofty',
              },
              {
                id: 'header-mobile-developments-item-smart-brickell',
                content: 'SMART BRICKELL TOWER 3',
                url: 'https://www.momentumluxhomes.com/smart-brickell-t3',
              },
              {
                id: 'header-mobile-developments-item-the-crosby',
                content: 'THE CROSBY MIAMI WORLDCENTER',
                url: 'https://www.momentumluxhomes.com/the-crosby-miami',
              },
              {
                id: 'header-mobile-developments-item-waldorf',
                content: 'WALDORF MIAMI',
                url: 'https://www.momentumluxhomes.com/waldorf-miami',
              },
            ],
          },
          {
            id: 'header-mobile-financing-item',
            content: 'FINANCING',
            url: 'https://www.momentumluxhomes.com/financing',
          },
          {
            id: 'header-mobile-team-item',
            content: 'TEAM',
            url: 'https://www.momentumluxhomes.com/team-page',
          },
          {
            id: 'header-mobile-contact-item',
            content: 'CONTACT US',
            url: 'https://www.momentumluxhomes.com/new-contact-us',
          },
          {
            id: 'header-mobile-blog-item',
            content: 'BLOG',
            url: 'https://www.momentumluxhomes.com/blog',
          },
          {
            id: 'header-mobile-home-item',
            content: 'HOME',
            url: 'https://www.momentumluxhomes.com/',
          },
        ],
      },
    },
  ],
  footers: {
    id: 'on-vrgada-footer-wrapper',
    className: 'on-vrgada-footer-wrapper',
    footer: [
      {
        id: 'on-vrgada-footer-first',
        display: true,
        className: 'on-vrgada-footer-section on-vrgada-footer-section-first',
        sectionClassName: 'on-vrgada-footer-section-container',
        columns: [
          {
            id: 'col-one',
            className: 'on-vrgada-footer-column on-vrgada-footer-column-one ',
            style: {},
            items: [
              {
                id: 'col-one-img',
                type: 'image',
                className: 'on-vrgada-footer-img',
                alt: 'img',
                url: 'https://si-homelight.s3.amazonaws.com/sites/vrgada/logo-footer.png',
                style: { width: '264px' },
              },
              {
                id: 'col-one-heading-one',
                type: 'heading',
                className: 'on-vrgada-footer-copyright-text pt-2',
                style: { fontWeight: 'bold' },
                data: '©Side INC 2022',
              },
              {
                id: 'col-one-heading-five',
                type: 'heading',
                className: 'on-vrgada-footer-copyright-text pb-3',
                style: {},
                data: 'All Rights Reserved',
              },
              {
                id: 'col-one-socials',
                type: 'socialIcons',
                className: 'on-vrgada-footer-socials',
                itemClassName: 'on-vrgada-footer-socials-item',
                style: {},
                items: [
                  {
                    id: 'col-one-facebook',
                    className: '',
                    iconTypeClassName: 'fab',
                    iconClassName: 'facebook-f',
                    url: 'https://www.facebook.com/mikesellsthesouthbayarea/',
                  },
                  {
                    id: 'col-one-insta',
                    className: '',
                    iconTypeClassName: 'fab',
                    iconClassName: 'instagram',
                    url: 'https://www.instagram.com/magicmikeramos/',
                  },
                  {
                    id: 'col-one-linked',
                    className: '',
                    iconTypeClassName: 'fab',
                    iconClassName: 'linkedin-in',
                    url: 'https://www.linkedin.com/in/mikeramosrealtor/',
                  },
                  {
                    id: 'col-one-twitter',
                    className: '',
                    iconTypeClassName: 'fab',
                    iconClassName: 'twitter',
                    url: 'https://twitter.com/MagicMikeRamos',
                  },
                  {
                    id: 'col-one-youtube',
                    className: '',
                    iconTypeClassName: 'fab',
                    iconClassName: 'youtube',
                    url: 'https://www.youtube.com/user/MikeRamosYourRealtor',
                  },
                ],
              },
            ],
          },
          {
            id: 'col-two',
            className: 'on-vrgada-footer-column ',
            style: { textAlign: 'left' },
            items: [
              {
                id: 'col-two-heading',
                type: 'heading',
                elementType: 'h1',
                className: 'on-vrgada-footer-heading',
                style: {},
                data: 'Popular Pages',
              },
              {
                id: 'col-two-menu',
                type: 'menu',
                className: 'on-vrgada-footer-menu',
                itemClassName: 'mb-0 on-vrgada-footer-menu-popular',
                style: { display: 'grid' },
                items: [
                  {
                    id: 'col-two-item-one',
                    data: 'Buy',
                    url: 'https://search.momentumluxhomes.com/',
                  },
                  {
                    id: 'col-two-item-two',
                    data: 'Sell',
                    url: 'https://www.momentumluxhomes.com/home-valuation',
                  },
                  {
                    id: 'col-two-item-three',
                    data: 'Communities',
                    url: 'https://www.momentumluxhomes.com/communities',
                  },
                  {
                    id: 'col-two-item-four',
                    data: 'New Developments',
                    url: 'https://www.momentumluxhomes.com/condos',
                  },
                  {
                    id: 'col-two-item-four',
                    data: 'Financing',
                    url: 'https://www.momentumluxhomes.com/financing',
                  },
                  {
                    id: 'col-two-item-five',
                    data: 'Team',
                    url: 'https://www.momentumluxhomes.com/team-page',
                  },
                  {
                    id: 'col-two-item-six',
                    data: 'Contact Us',
                    url: 'https://www.momentumluxhomes.com/new-contact-us',
                  },
                  {
                    id: 'col-two-item-eight',
                    data: 'Blog',
                    url: 'https://www.momentumluxhomes.com/blog',
                  },
                  {
                    id: 'col-two-item-nine',
                    data: 'Home',
                    url: 'https://www.momentumluxhomes.com/',
                  },
                ],
              },
            ],
          },
          {
            id: 'col-three',
            className: 'on-vrgada-footer-info  ',
            style: {},
            items: [
              {
                id: 'col-three-menu',
                type: 'menu',
                className: ' pt-xl-1',
                itemClassName: 'mb-0',
                style: {},
                items: [
                  {
                    id: 'col-three-item-one',
                    data: 'Momentum Luxury Real Estate',
                    className: 'on-vrgada-footer-info-item',
                  },
                  {
                    id: 'col-three-item-two',
                    data: '10837 Garden Ridge Ct',
                    className: 'on-vrgada-footer-info-item',
                  },
                  {
                    id: 'col-three-item-three',
                    data: 'Davie, FL 33328',
                    className: 'on-vrgada-footer-info-item',
                  },
                ],
              },
              {
                id: 'col-three-menu-two',
                type: 'itemContent',
                className: 'mb-0 on-vrgada-footer-contact pt-2',
                style: {},
                items: [
                  {
                    id: 'col-three-item-four',
                    content: 'Phone: ',
                  },
                  {
                    id: 'col-three-item-five',
                    content: '(305) 894-6865',
                    url: 'tel:(305) 894-6865',
                  },
                ],
              },
              {
                id: 'col-three-menu-three',
                type: 'itemContent',
                className: 'mb-0 on-vrgada-footer-contact',
                style: {},
                items: [
                  {
                    id: 'col-three-item-six',
                    content: 'Email: ',
                  },
                  {
                    id: 'col-three-item-seven',
                    content: 'Mike@Momentumlux.com',
                    url: 'mailto:Mike@Momentumlux.com',
                  },
                ],
              },
            ],
          },
          {
            id: 'col-four',
            className: 'on-vrgada-footer-column on-vrgada-footer-column-four ',
            style: {},
            items: [
              {
                id: 'col-four-img',
                type: 'image',
                className: 'on-vrgada-footer-img',
                alt: 'img',
                url: 'https://si-homelight.s3.amazonaws.com/sites/vrgada/logo-footer.png',
                style: { width: '264px' },
              },
              {
                id: 'col-four-heading-four',
                type: 'heading',
                className: 'on-vrgada-footer-copyright-text pt-2',
                style: { fontWeight: 'bold' },
                data: '©Side INC 2022',
              },
              {
                id: 'col-four-heading-five',
                type: 'heading',
                className: 'on-vrgada-footer-copyright-text pb-3',
                style: {},
                data: 'All Rights Reserved',
              },
              {
                id: 'col-four-socials',
                type: 'socialIcons',
                className: 'on-vrgada-footer-socials',
                itemClassName: 'on-vrgada-footer-socials-item',
                style: {},
                items: [
                  {
                    id: 'col-four-facebook',
                    className: '',
                    iconTypeClassName: 'fab',
                    iconClassName: 'facebook-f',
                    url: 'https://www.facebook.com/mikesellsthesouthbayarea/',
                  },
                  {
                    id: 'col-four-insta',
                    className: '',
                    iconTypeClassName: 'fab',
                    iconClassName: 'instagram',
                    url: 'https://www.instagram.com/magicmikeramos/',
                  },
                  {
                    id: 'col-four-linked',
                    className: '',
                    iconTypeClassName: 'fab',
                    iconClassName: 'linkedin-in',
                    url: 'https://www.linkedin.com/in/mikeramosrealtor/',
                  },
                  {
                    id: 'col-four-twitter',
                    className: '',
                    iconTypeClassName: 'fab',
                    iconClassName: 'twitter',
                    url: 'https://twitter.com/MagicMikeRamos',
                  },
                  {
                    id: 'col-four-youtube',
                    className: '',
                    iconTypeClassName: 'fab',
                    iconClassName: 'youtube',
                    url: 'https://www.youtube.com/user/MikeRamosYourRealtor',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: 'on-vrgada-footer-second',
        display: true,
        className: 'on-vrgada-footer-section on-vrgada-footer-section-last',
        sectionClassName: 'on-vrgada-footer-section-container',
        columns: [
          {
            id: 'footer-disclaimer',
            className: 'align-center on-vrgada-footer-privacy-col',
            style: {},
            items: [
              {
                id: 'footer-privacy-policy',
                type: 'heading',
                elementType: 'a',
                url: 'https://www.momentumluxhomes.com/#',
                className: 'on-vrgada-footer-privacy-heading pt-5 pb-4',
                style: { fontSize: '13px' },
                data: 'PRIVACY POLICY',
              },
              {
                id: 'footer-privacy-text',
                type: 'heading',
                className: 'on-vrgada-footer-text pt-2',
                elementType: 'p',
                style: {},
                data: 'Copyright © 2022 Miami Association of REALTORS. All Rights Reserved.              ',
              },
              {
                id: 'footer-privacy-text-two',
                type: 'heading',
                className: 'on-vrgada-footer-text',
                elementType: 'p',
                style: {},
                data: "IDX information courtesy of Miami Association of Realtors. IDX information is provided exclusively for consumers' personal, non-commercial use, and may not be used for any purpose other than to identify prospective properties consumers may be interested in purchasing. Data is deemed reliable but is not guaranteed accurate by the MLS.",
              },
            ],
          },
        ],
      },
    ],
  },
  substitute: {},
  replace: {},
  siteData: {
    agency: 'Momentum Luxury Real Estate',
    agentDescription: 'Top agents in South Florida',
    agentAvatar: 'https://si-homelight.s3.amazonaws.com/sites/vrgada/agent.jpg',
    heroBackground: 'https://si-homelight.s3.amazonaws.com/sites/vrgada/hero-bg.jpg',
    expressFee: '1.5%',
  },
};
