import React, { memo } from 'react';

import './footer-container.scss';
import { Footer } from '@on-haiti/shared-ui/components';

const FooterContainer = ({ metadata }) => {
  return (
    <div className={metadata.className} style={metadata.style} id={metadata.id}>
      {metadata?.footer?.map((footer) => (
        <Footer key={footer.id} id={footer.id} metadata={footer} />
      ))}
    </div>
  );
};

export default memo(FooterContainer);
